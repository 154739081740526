import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

import { GlobalState } from '../../../global.state';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MobileUtil } from '../../../utils/mobileUtil.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utils/util.service';
import { EVENT_NAMES, PAGE_NAMES } from '../../../utils/enums';

// import styleVariables from 'variables';

@Component( {
  selector: 'nga-ba-content-top',
  styleUrls: [ './baContentTop.scss' ],
  templateUrl: './baContentTop.html',
} )
export class BaContentTopComponent implements OnDestroy {

  @ViewChild( 'tableContainer', { static: false } ) tableContainer: ElementRef;

  private readonly pagesWithGlobalWidget: string[] = [
    PAGE_NAMES.HOLDINGS, PAGE_NAMES.ACCOUNTS, PAGE_NAMES.BALANCE_SHEET,
  ];

  private readonly onDestroy: Subject<void> = new Subject<void>();

  activePageTitle: string = '';
  menuIsCollapsed: boolean;

  protected _onRouteChange: Subscription;

  ngOnDestroy(): void {
    this._state.unsubscribe( [ EVENT_NAMES.MENU_IS_COLLAPSED, 'column.group.changed' ].join( ' | ' ), this.subscriberName );
    this._onRouteChange.unsubscribe();
  }

  subscriberName: string = 'contentTopComponent';

  deviceIsMobile: boolean = false;

  showGlobalWidget: boolean = true;

  constructor( private _state: GlobalState,
               public router: Router,
               private _detectorService: DeviceDetectorService ) {

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    if ( environment.env !== 'prod' ) {
      window['ripsaw_contentTop'] = this;
    }

    this._onRouteChange = router.events
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( event ) => {
        if ( event instanceof NavigationEnd ) {
          // console.log( event );
          const baseUrl = event.url.split( '?' )[0]; // only care about the base url, ignore query string parameters
          const newActiveLink = this._state.activeLinks[baseUrl];
          this.checkNewPageLink( newActiveLink );
        }
      } );

    _state.globalVars.editing = false;

    this._state.subscribe( EVENT_NAMES.MENU_IS_COLLAPSED, ( isCollapsed: boolean ) => {
      this.menuIsCollapsed = isCollapsed;
    }, this.subscriberName );

    // only care about the base url, ignore query string parameters
    const activeLink = this._state.activeLinks[this.router.url.split( '?' )[0]];
    this.setGlobalWidgetVisibility( activeLink );
  }

  /**
   *
   * @param activeLink {string} - name of the current active page
   */
  checkNewPageLink( activeLink: string | any ): void {
    this.setGlobalWidgetVisibility( activeLink );
    if ( activeLink ) {
      this.activePageTitle = activeLink.title || activeLink;
      // console.log( this.activePageTitle );
      if ( this.showGlobalWidget ) {
        Util.scrollToFirstColumn( this._state.globalVars.allocWidget );
      }
    }

  }

  /**
   *
   * @param activeLink - page name of the current page. if it is in this.pagesWithGlobalWidget, then the widget should be shown
   */
  setGlobalWidgetVisibility( activeLink: string ) {
    this.showGlobalWidget = this.pagesWithGlobalWidget.includes( activeLink );
  }

}
