/**
 * Created by joshua on 12/24/16.
 */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { Auth } from '../../auth.service';
import { GlobalState } from '../../global.state';
import * as _ from 'lodash-es';
import {
  Account,
  AllocationData,
  AllocationWidgetTableRow,
  AllocCalculator,
  BenchmarkDetailCell,
  BenchmarkUtil,
  ColorName,
  ExpensesInfoItem,
  Position,
} from '@ripsawllc/ripsaw-analyzer';
import { RipsawCurrencyPipe, RipsawPercentPipe, RipsawTwoPercentPipe } from '../../theme/pipes';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Util } from '../../utils/util.service';
import { AccountManager } from '../../utils/accountManager';
import { environment } from '../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretSquareDown } from '@fortawesome/free-solid-svg-icons/faCaretSquareDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faPercent } from '@fortawesome/free-solid-svg-icons/faPercent';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { UsersUtil } from '../../utils/users.util';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { AllocationChartsComponent } from './components/allocationCharts';
import { OneDayChangeUtil } from '../../utils/oneDayChangeUtil';
import { RipThemeLoadingSpinnerService } from '../../theme/services';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimersUtil } from '../../utils/disclaimers.util';
import { OneDayBreakdownDialogComponent } from '../../pages/modals/oneDayBreakdownDialog/one-day-breakdown-dialog.component';
import { BenchmarkState } from '../../utils/benchmark.state';
import { GlossaryUtil } from '../../utils/glossary.util';
import { NbPopoverDirective, NbPosition } from '@nebular/theme';
import { BaseChartDirective } from 'ng2-charts';
import { ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { ChartColorUtil } from '../../utils/chart-color.util';
import { EVENT_NAMES, PAGE_NAMES } from '../../utils/enums';
import { AppStoreService } from '../../store';
import { OverridesDialogUtil } from '../../utils/overiddesDialog.util';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component( {
  selector: 'rip-allocation-widget',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [ './allocationWidget.scss' ],
  templateUrl: './allocationWidget.component.html',
} )
export class AllocationWidgetComponent
  implements AfterViewInit, OnInit, OnDestroy {
  chartIndexAxis: string = 'y';

  faInfoCircle: IconDefinition = faInfoCircle;
  faCaretDown: IconDefinition = faCaretDown;
  faCaretUp: IconDefinition = faCaretUp;
  faQuestionCircle: IconDefinition = faQuestionCircle;
  faExclamationCircle: IconDefinition = faExclamationCircle;
  faCircle: IconDefinition = faCircle;
  faPercent: IconDefinition = faPercent;
  faDollarSign: IconDefinition = faDollarSign;
  faColumns: IconDefinition = faColumns;
  faSquare: IconDefinition = faSquare;

  readonly missingDataIndicatorColor: any = Util.getDeviationColorObjectByName(
    ColorName.red,
  );

  private readonly onDestroy: Subject<void> = new Subject<void>();

  @ViewChild( 'expensesTemplate', { static: true } ) expenseTemplate: TemplateRef<any>;
  @ViewChild( 'headerTemplate', { static: false } ) headerTemplate: TemplateRef<any>;
  @ViewChild( 'verticalHeaderTemplate', { static: false } ) verticalHeaderTemplate: TemplateRef<any>;
  @ViewChild( 'genericTemplate', { static: true } ) genericTemplate: TemplateRef<any>;
  @ViewChild( 'genericMobileTemplate', { static: true } ) genericMobileTemplate: TemplateRef<any>;
  @ViewChildren( NbPopoverDirective ) popovers: QueryList<NbPopoverDirective>;
  @ViewChild( 'allocTableContainer', { static: false } ) tableContainerDiv: ElementRef;
  @ViewChild( 'widgetRowDiv', { static: false } ) widgetRowDiv: ElementRef;
  @ViewChild( 'loadingMessageDiv', { static: false } ) loadingMessageDiv: ElementRef;
  @ViewChild( 'carouselItemTemplate', { static: true } ) carouselItemTemplate: TemplateRef<any>;
  @ViewChild( 'oneDayChangeTemplate', { static: true } ) oneDayChangeTemplate: TemplateRef<any>;
  @ViewChild( 'allocationCharts', { static: false } ) allocationCharts: AllocationChartsComponent;
  @ViewChild( 'dimensionTemplate', { static: false } ) dimensionTemplate: TemplateRef<any>;
  @ViewChild( 'caretTemplate', { static: false } ) caretTemplate: TemplateRef<any>;
  @ViewChild( 'legendTemplate', { static: false } ) legendTemplate: TemplateRef<any>;
  @ViewChild( 'realAssetsBreakdownChart' ) realAssetsBreakdownChartDirective: BaseChartDirective;

  @ViewChildren( DatatableComponent ) datatables: QueryList<DatatableComponent>;
  // @ViewChild( 'allocWidgetTable' ) mainDatatable: ElementRef;

  @Input() account: any;
  activePageTitle: string;
  loadingAccounts: boolean = true;
  initialLoadDone: boolean = false;
  overrideSecurityDataLoaded: boolean = false;
  progressValue: number = 10;

  progressMessage: any = {
    10: 'Initializing...',
    15: 'Loading Security Data...',
    25: 'Retrieving Latest Market Prices...',
    50: 'Retrieving Account Data...',
    65: 'Charging FTL Drive...',
    75: 'Retrieving Institution Connection Information...',
    90: 'Aggregating Account Data...',
    100: 'Account Data Aggregation Complete',
  };

  rows: any = [];
  mobileRows: any = [];

  minimized: boolean = false;

  columns = [];
  mobileColumns = [];
  initialColumns = [];
  widgetRowClass: string = 'widget-row';
  currentColumnSet: any = {};

  showRE: boolean = true;

  tableData: AllocationWidgetTableRow[] = [];
  transposedTableData: any[] = [];

  benchmarkOneDayBreakdownRows: any[] = [];
  benchmarkOneDayBreakdownColumns: any[] = [];
  portfolioOneDayBreakdownRows: any[] = [];
  portfolioOneDayBreakdownColumns: any[] = [];
  oneDayBreakdownTableMessages = {
    // Message to show when array is presented
    // but contains no values
    emptyMessage: 'No Holdings With One Day Breakdown',

    // Footer total message
    totalMessage: 'Holdings',

    // Footer selected message
    selectedMessage: 'Selected',
  };

  oneDayBreakdownTableCssClasses = {
    sortAscending: 'datatable-icon-down',
    sortDescending: 'datatable-icon-up',
    pagerLeftArrow: 'datatable-icon-left',
    pagerRightArrow: 'datatable-icon-right',
    pagerPrevious: 'datatable-icon-prev',
    pagerNext: 'datatable-icon-skip',
  };

  showDiffs: boolean = false;
  showPercentDiffs: boolean = false;

  relevantAllocData: AllocationData;
  // relevantBenchmarkData: any = {};

  showTable: boolean = true;
  flip: string = 'inactive';

  chartNumberFormatter;

  loadingCharts: boolean = true;

  slides: any[];
  nextLabel = 'Next';
  prevLabel = 'Back';

  subscriberName: string;

  groupDeviations: any = {};
  thresholds: any = {};

  tableHeaderHeight: number = 35;
  tableRowHeight: number = 55;
  mobileTableHeaderHeight: number = 35;
  mobileTableRowHeight: number = 55;

  chartsSpinnerSelector: string = 'alloc-charts-spinner';

  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripTwoPercentPipe: RipsawTwoPercentPipe = new RipsawTwoPercentPipe();
  deviceIsMobile: boolean = false;
  deviceIsDesktop: boolean = true;

  currentChartIndex: number = 0;

  maxChartHeight: number = 850;

  realAssetsBreakdown: any = {};
  bottomPlacement: NbPosition = NbPosition.BOTTOM;
  realAssetChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: ( event, items ) => {
      if ( items.length > 0 ) {
        const item = items[ 0 ];
        this.realAssetsBreakdownChartDirective?.update();
      }
    },

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {},
      tooltip: {
        callbacks: {
          label: ( context: TooltipItem<ChartType> ) => {
            return context.label;
          },
        },
      },
      /*title: {
       display: true,
       text: 'Real Asset Breakdown',
       fontColor: '#79afd7', // these three lines will need updating once we can move to chartjs 3.x
       fontStyle: 'bolder',
       fontSize: this._state.chartTitleHeight,
       },*/
    },
    layout: {
      padding: 25,
    },
  };
  realAssetChartType: ChartType = 'pie';

  readonly realAssetCategories: any = {
    realEstate: 'Real Estate',
    commodity: 'Commodities',
    crypto: 'Crypto Currency',
    vehicle: 'Vehicle(s)',
    valuables: 'Valuable(s)',
    otherAlternatives: 'Other Alternatives',
  };

  realAssetBreakdownColumns: any;
  readonly realAssetsBreakdownChartColors = ChartColorUtil.getColorGradients(
    Object.keys( this.realAssetCategories ).length,
  );

  showMobileOneDayPerf: boolean = false;

  @HostListener( 'window:resize', [ '$event' ] )
  onResize(/*event: Event*/ ) {
    this.notifyHeightChanged();
  }

  constructor(
    private _auth: Auth,
    private _state: GlobalState,
    private _elRef: ElementRef,
    private _ngZone: NgZone,
    private _accountManager: AccountManager,
    private _renderer: Renderer2,
    private _cd: ChangeDetectorRef,
    private _spinnerService: RipThemeLoadingSpinnerService,
    public dialog: MatDialog,
    private disclaimersUtil: DisclaimersUtil,
    private glossaryUtil: GlossaryUtil,
    private benchmarkState: BenchmarkState,
    private _detectorService: DeviceDetectorService,
    private appStoreService: AppStoreService,
    private _overridesDialogUtil: OverridesDialogUtil,
  ) {
    this._accountManager.allDataRetrieved
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( val: boolean ) => {
          this.recalc();
          this.overrideSecurityDataLoaded = val;
        },
      } );
    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );
    this.deviceIsDesktop = MobileUtil.deviceIsDesktop( this._detectorService );
  }

  ngOnInit(): void {
    // setup the starting columns. Have to do this in OnInit because you need the template view child to be available
    this.initialColumns = this.getInitialColumns();

    this.addHeaderTemplate( this.initialColumns );
    this.showProgressAfterWorkspaceLoad();
  }

  // TODO: refactor a lot of the data gathering functions and variables into a state util so the data is available
  //  more easily without the widget component needing to be initialized
  /*
   * setup listeners and initialize some variables
   * */
  ngAfterViewInit(): any {
    setTimeout( () => {
      if ( this.account ) {
        if ( environment.env !== 'prod' ) {
          window[ `ripsaw_allocationWidget_${ this.account.account_id }` ] = {
            component: this,
            zone: this._ngZone,
          };
        }
        this.subscriberName = `allocationWidgetComponent_${ this.account.account_id }`;
      } else {
        if ( environment.env !== 'prod' ) {
          window[ 'ripsaw_allocationWidget' ] = this;
        }
        this.subscriberName = 'allocationWidgetComponent';

        this._state.globalVars.allocWidget = this;

        this.benchmarkOneDayBreakdownColumns =
          this.getOneDayBreakdownColumns( true );
        this.portfolioOneDayBreakdownColumns =
          this.getOneDayBreakdownColumns( false );

      }
      this.overrideSecurityDataLoaded = true;
      // this listener is for when editing flag changes and we need to add or remove the revised row
      this._state.subscribe(
        EVENT_NAMES.EDITING_CHANGED,
        editingState => {
          this._state.globalVars.editing = editingState;
          this.calculateAllocations( editingState );
          if ( !editingState ) {
            this.removeRevisedAllocations();
          }
          this.toggleInfoType( this._state.globalVars.infoType );
        },
        this.subscriberName,
      );
      // this listener is for when the column sets need to be changed
      this._state.subscribe(
        'infoType.changed',
        infoType => {
          this.toggleInfoType( infoType );
          if ( !this.account ) {
            this.generateSlides();
          }
        },
        this.subscriberName,
      );
      // this listener is for when the user navigates to another page and we need to remove the revised row if it's there
      this._state.subscribe(
        'menu.activeLink',
        activeLink => {
          if ( activeLink ) {
            this.activePageTitle = activeLink.title;
            if ( this.activePageTitle !== PAGE_NAMES.ACCOUNTS && !this.account ) {
              this.removeRevisedAllocations();
              // this.notifyHeightChanged();
            }
            // console.log( this.activePageTitle );
          }
          // this.getDataGrid().recalculate();
        },
        this.subscriberName,
      );
      this._state.subscribe(
        EVENT_NAMES.MENU_IS_COLLAPSED,
        ( isCollapsed: boolean ) => {
          if ( !this.account && this.widgetRowDiv ) {
            if ( isCollapsed ) {
              // this.tableContainerDiv.nativeElement.style.width = '';
              const widgetWrapper =
                this._elRef.nativeElement.querySelector( '.widgetWrapper' );
              if ( widgetWrapper ) {
                widgetWrapper.style.width = '100%';
              }
              this.triggerRerenderOfDatatable( true );
            } else {
              // this.tableContainerDiv.nativeElement.style.width = '100%';
              /*const widgetWrapper = this._elRef.nativeElement.querySelector( '.widgetWrapper' );
               if ( widgetWrapper ) {
               widgetWrapper.style.width = '101.3%';
               }*/
              this.triggerRerenderOfDatatable( true );
            }
            this.generateSlides();
          }
        },
        this.subscriberName,
      );
      // this listener is for when the real estate button in the page top is clicked and we need to recalculate allocations with or without real
      // estate assets/liabilities
      this._state.subscribe(
        'RE.toggled',
        showRE => {
          this.showRE = showRE;
          this.calculateAllocations( false );
          this.calcAllocDatas();
          const nwCol = this.columns.find( c => c.prop === 'net_worth' );
          nwCol.name = this.getNetWorthName();
          // this.setBenchmark( this.benchmark );
          this.toggleInfoType( this._state.globalVars.infoType );
          // this.getDataGrid().recalculate();
        },
        this.subscriberName,
      );
      // this listener is for when we need to recalculate allocations (generally for when changes are made to positions, but can be triggered by
      // other events)
      this._state.subscribe(
        [ EVENT_NAMES.RECALCULATE_ALLOCATIONS, EVENT_NAMES.PRICES_UPDATED ].join(
          ' | ',
        ),
        () => {
          this.recalc();
        },
        this.subscriberName,
      );
      //
      this._accountManager.allDataRetrieved.subscribe( {
          next: () => {
            this.calcAllocDatas();
            this.loadingAccounts = false;
            this.initialLoadDone = true;
            if ( !this.account ) {
              this.generateSlides();
              if ( this.deviceIsMobile ) {
                setTimeout( () => {
                  this.recalc();
                  this._state.notifyDataChanged(
                    'notify.mobile.one.day.component',
                    {
                      portfolioOneDayBreakdownRows:
                      this.portfolioOneDayBreakdownRows,
                      portfolioOneDayBreakdownColumns:
                      this.portfolioOneDayBreakdownColumns,
                      benchmarkOneDayBreakdownRows:
                      this.benchmarkOneDayBreakdownRows,
                      benchmarkOneDayBreakdownColumns:
                      this.benchmarkOneDayBreakdownColumns,
                    },
                  );
                } );
              }
              // }
              // console.log( 'benchmark form setup' );
              // this.notifyHeightChanged();
            }
          }, error: ( err ) => {
            console.error( err );
          },
        },
      );

      this._state.subscribe(
        EVENT_NAMES.PROGRESS_UPDATE,
        ( progress: number ) => {
          this.progressValue = progress;
        },
        this.subscriberName,
      );

      this._state.subscribe(
        'goto.column.group',
        ( group: any ) => {
          if ( !this.account || this.account.open ) {
            this.goToColumnGroup( group );
          }
        },
        this.subscriberName,
      );

      this._state.subscribe(
        'goto.first.column',
        () => {
          if ( !this.account || this.account.open ) {
            this._state.globalVars.currentColumnGroup =
              this._state.columnGroupings[ 0 ];
            Util.scrollToFirstColumn( this );
          }
        },
        this.subscriberName,
      );

      this._state.subscribe(
        'toggle.benchmark.values',
        () => {
          this.toggleBenchmarkValues();
        },
        this.subscriberName,
      );

      if ( this._state.globalVars.infoType ) {
        this.toggleInfoType( this._state.globalVars.infoType );
      }

      this.columns = [
        ...this.initialColumns,
        ...this.getColumns(
          this._state.globalVars.infoType ||
          this._state.globalVars.defaultColumnSetLabel,
        ),
      ];
      this.addHeaderTemplate( this.columns );
      this.minimized = !!this.account;
      this.loadingAccounts = this._state.globalVars.loadingAccounts;
      if ( !this.loadingAccounts || this.account ) {
        this.calcAllocDatas();
      }

      this.realAssetBreakdownColumns = [
        /*{
         prop: 'color',
         cellTemplate: this.legendTemplate,
         },*/
        {
          prop: 'category',
          name: '',
          minWidth: 150,
          cellTemplate: this.legendTemplate,
          sortable: false,
        },
        {
          prop: 'percent',
          name: '% of Real Assets',
          pipe: this.ripPercentPipe,
          minWidth: 135,
        },
        {
          prop: 'dollar',
          name: 'Total Dollar Amount',
          pipe: this.ripCurrencyPipe,
          minWidth: 135,
        },
        {
          prop: 'percentOfTotal',
          name: '% of Total Portfolio',
          pipe: this.ripPercentPipe,
          minWidth: 145,
        },
      ];
      // this.notifyHeightChanged();
    } );
  }

  generateSlides() {
    if ( !this.account && !this.loadingAccounts ) {
      this._spinnerService.show( this.chartsSpinnerSelector );

      if ( this.allocationCharts ) {
        this.allocationCharts.slideLoading = true;
      }
      this.slides = [];
      const set = _.cloneDeep( this.currentColumnSet );
      if (
        !this.deviceIsMobile &&
        !set.columns.includes( 'Cash, Bonds, Stocks' )
      ) {
        set.columns.push( 'Cash, Bonds, Stocks' );
      }
      for ( const c of set.columns ) {
        const group = this._state.columnGroupings.find( ( g: any ) => {
          return g.label === c;
        } );
        if ( group ) {
          this.slides.push( {
            // title: group.label,
            group: group.label,
            template: this.carouselItemTemplate,
            context: { chartObject: this.getChartObject( group ) },
          } );
        } else {
          // c is actually just a single column, should we do something special here?
        }
      }
      this._state.notifyDataChanged( 'slides.generated', this.slides );
      if ( !this.showTable ) {
        this._spinnerService.hide( 0, this.chartsSpinnerSelector );
      }
      if ( this.allocationCharts ) {
        this.allocationCharts.goToCarouselSlide( this.currentChartIndex );
      }
    }
  }

  /*
   * setup initial columns before infoType is set and sets up the rest of the columns
   * */
  getInitialColumns() {
    return [
      {
        name: '',
        prop: 'header',
        sortable: false,
        cellTemplate: this.verticalHeaderTemplate,
        // frozenLeft: true,
        cellClass: Util.getCellClass,
      },
      {
        name: this.getNetWorthName(),
        prop: 'net_worth',
        sortable: false,
        cellClass: Util.getCellClass,
      },
      {
        name: '1-Day Return',
        prop: 'one_day_change',
        cellTemplate: this.oneDayChangeTemplate,
        cellClass: 'one-day-change-cell',
        minWidth: 175,
        disclaimer: 0,
      },
      // {
      //   name: 'Real',
      //   longName: 'Real Assets',
      //   prop: 'real_assets',
      //   sortable: false,
      //   cellClass: Helpers.getCellClass,
      // },
      // {
      //   name: 'Cash',
      //   prop: 'cash',
      //   sortable: false,
      //   cellClass: Helpers.getCellClass,
      // },
      // {
      //   name: 'Bonds',
      //   prop: 'bonds',
      //   sortable: false,
      //   cellClass: Helpers.getCellClass,
      // },
      // {
      //   name: 'Stocks',
      //   prop: 'stocks',
      //   sortable: false,
      //   cellClass: Helpers.getCellClass,
      // },
      // {
      //   name: 'Expenses',
      //   longName: 'Total Expenses',
      //   prop: 'expenses',
      //   cellTemplate: this.expenseTemplate,
      //   sortable: false,
      //   cellClass: Helpers.getCellClass,
      // },
    ];
  }

  getMobileColumns() {
    return [
      {
        name: '',
        prop: 'dimension',
        sortable: false,
        minWidth: 80,
        cellTemplate: this.dimensionTemplate,
      },
      {
        name: 'Current',
        prop: 'current',
        sortable: false,
        minWidth: 100,
        cellTemplate: this.genericMobileTemplate,
        cellClass: 'transposed-two-line-cell',
      },
      {
        name: this.showDiffs ? 'Difference From Benchmark' : 'Benchmark',
        prop: 'benchmark',
        sortable: false,
        minWidth: 100,
        cellTemplate: this.genericMobileTemplate,
        cellClass: 'transposed-two-line-cell',
      },
    ];
  }

  getOneDayBreakdownColumns( bm: boolean ) {
    const cellClassFunction = ( { row, column } ) => {
      return {
        up: row.dollarChange > 0,
        down: row.dollarChange < 0,
        // 'right-align': column.prop === 'ticker' || column.prop === 'dollarChange' || column.prop === 'percentChange',
      };
    };

    const bmColumns: any[] = [
      {
        name: 'Market Sector',
        prop: 'market_sector',
        sortable: false,
        // minWidth: 200,
      },
    ];

    const portfolioColumns: any[] = [
      {
        name: 'Description',
        prop: 'ticker_name',
        sortable: false,
        // minWidth: 200,
      },
    ];

    const columns: any[] = [
      {
        name: 'Ticker',
        prop: 'ticker',
        sortable: false,
        // cellTemplate: this.caretTemplate,
        // headerClass: 'right-align',
        cellClass: cellClassFunction,
        // minWidth: 100,
        // maxWidth: 80,
      },
      {
        name: '1-Day Return',
        prop: 'one_day_change',
        sortable: false,
        // headerClass: 'right-align',
        cellClass: cellClassFunction,
        // minWidth: 150,
        // maxWidth: 110,
      },

      // {
      //   name: 'Portfolio Weighted Value ($)',
      //   prop: 'value',
      //   sortable: false,
      //   pipe: this.ripCurrencyPipe,
      //   minWidth: 175,
      // },
      // {
      //   name: 'Portfolio Weighted Value (%)',
      //   prop: 'portfolio_allocation',
      //   sortable: false,
      //   pipe: this.ripPercentPipe,
      //   minWidth: 185,
      // },
    ];

    if ( bm ) {
      return bmColumns.concat( columns );
    } else {
      return portfolioColumns.concat( columns );
    }
  }

  /*
   * toggle whether to show real assets in allocation data
   * */
  toggleRE() {
    this._state.globalVars.showRE = !this.showRE;
    this._state.notifyDataChanged( 'RE.toggled', !this.showRE );
  }

  toggleShowPercentDiffs() {
    this.showPercentDiffs = !this.showPercentDiffs;
    this.generateSlides();
  }

  /*
   * add the header template to all of the columns
   * */
  addHeaderTemplate( cols ) {
    // console.log( 'adding header template to allocation widget columns...' );
    for ( const col of cols ) {
      col.headerTemplate = this.headerTemplate;
      col.sortable = false;
      if ( ![ 'header', 'expenses', 'one_day_change' ].includes( col.prop ) ) {
        // add template
        col.cellTemplate = this.genericTemplate;
        delete col.pipe;
      }
      if ( col.prop === 'expenses' ) {
        col.cellTemplate = this.expenseTemplate;
      }
      if ( col.prop === 'header' ) {
        col.cellTemplate = this.verticalHeaderTemplate;
      }
    }
  }

  /*
   * Get the label for the net worth column
   * */
  getNetWorthName() {
    return this.showRE
      ? this.account
        ? 'Balance'
        : 'Net Worth'
      : 'Investments';
  }

  goToColumnGroup( group: any, duration?: number ) {
    this._state.globalVars.currentColumnGroup = group;
    if ( !this.deviceIsMobile ) {
      if ( this.showTable ) {
        Util.scrollToColumn( group, this, duration );
      } else {
        const index = this.slides.findIndex( ( s: any ) => {
          return s.group === group.label;
        } );
        this.allocationCharts.goToCarouselSlide( index );
      }
    } else {
      this.transposeTableData();
    }
    this.doChanges();
  }

  recalc() {
    this.calcAllocDatas();
    if ( this.initialLoadDone ) {
      this.loadingAccounts = false;
    }
    if ( !this.account ) {
      if ( this.resizeListener ) {
        this.resizeListener();
      }
      this.resizeListener = this._renderer.listen(
        'window',
        'resize',
        (/*e*/ ) => {
          this.generateSlides();
        },
      );
    }
  }

  /*
   * this function is purely a way to trigger the datatable to re-render so that the columns are re-sized
   * properly after the addition of new columns
   * */
  triggerRerenderOfDatatable( keepScroll?: boolean ) {
    const datatable = this._elRef.nativeElement.querySelector( 'datatable-body' );
    if ( datatable ) {
      setTimeout( () => {
        let end = 0;
        if ( keepScroll ) {
          end = datatable.scrollLeft;
        }
        datatable.scrollLeft = 10;
        datatable.scrollLeft = end;
        this.getDataGrid().recalculate();
        this.doChanges();
      }, 100 );
    }
  }

  doChanges() {
    this._cd.detach();
    // this._cd.detectChanges();
    this._cd.markForCheck();
    this._cd.reattach();
  }

  resizeListener;

  ngOnDestroy(): void {
    this._state.globalVars.globalDashboardHeight = undefined;

    if ( this.account && !this.subscriberName ) {
      this.subscriberName = `allocationWidgetComponent_${ this.account.account_id }`;
    }

    this._state.unsubscribe(
      [
        'infoType.changed',
        'menu.activeLink',
        EVENT_NAMES.MENU_IS_COLLAPSED,
        'RE.toggled',
        EVENT_NAMES.RECALCULATE_ALLOCATIONS,
        EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
        EVENT_NAMES.WIDGET_RESIZE,
        EVENT_NAMES.EDITING_CHANGED,
        'goto.column.group',
        'goto.first.column',
        'toggle.benchmark.values',
      ].join( ' | ' ),
      this.subscriberName,
    );
    if ( !this.account && this.resizeListener ) {
      this.resizeListener();
    }
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  calcAllocDatas() {
    this.calculateAllocations( false );
    if ( this._state.globalVars.editing ) {
      this.calculateAllocations( true );
    }
    if ( !this.account ) {
      this.setBenchmark();
      this.setOneDayBreakdownData();
      this.notifyHeightChanged();
    } else {
      this._state.globalVars.accountAllocWidgets[ this.account.account_id ] =
        this;
    }
  }

  /*
   * set the class variable that holds the data for the table
   * */
  computeDisplayNumbers( allocData: AllocationData, allocDataType: string ) {

    if ( allocData ) {

      this.portfolioOneDayBreakdownRows = allocData.one_day_holdings;
      Util.sortPositions( 'dollarChange', this.portfolioOneDayBreakdownRows, 'dsc' );
      if ( this._state.globalVars.editing && allocDataType === 'Revised' ) {
        this.relevantAllocData = allocData;
      } else if (
        !this._state.globalVars.editing &&
        allocDataType === 'Current'
      ) {
        this.relevantAllocData = allocData;
      }

      const expenses_dollar: ExpensesInfoItem = {
        advisorFees: allocData.management_fee.value,
        expenseRatio: allocData.expense_ratio,
        totalExpenses: allocData.management_fee.value + allocData.expense_ratio,
      };

      const expenses_percent: ExpensesInfoItem = {
        advisorFees: allocData.management_fee.avg,
        expenseRatio: allocData.expense_ratio / allocData.investment_total,
        totalExpenses: ( allocData.expense_ratio + allocData.management_fee.value ) / allocData.investment_total,
      };

      const newTableDataRow: AllocationWidgetTableRow = AllocCalculator.convertAllocData( allocData, allocDataType, {
        dollar: expenses_dollar,
        percent: expenses_percent,
      } );

      if ( allocDataType === 'Current' ) {
        this.realAssetsBreakdown = newTableDataRow.real_assets_breakdown;
        this.makeRealAssetsChartData();
      }

      Util.convertNaNtoZero( newTableDataRow );

      _.remove( this.tableData, r => {
        return r.allocDataType === allocDataType;
      } );
      this.tableData.push( newTableDataRow );
      this.rows = [ ...this.tableData ];
      this.sortRows();
    } else {
      // console.log( 'allocations have not been calculated yet');
    }
  }

  makeRealAssetsChartData() {
    const piePieces = [];
    const labels = []; // ['Category | % of Real Assets | Total in Dollars | % of Total Portfolio'];
    const rows = [];
    let i = 0;
    for ( const key of Object.keys( this.realAssetsBreakdown.dollar ) ) {
      piePieces.push( this.realAssetsBreakdown.dollar[ key ] );
      labels.push(
        `${ this.realAssetCategories[ key ] }: ${ this.ripPercentPipe.transform(
          this.realAssetsBreakdown.percent[ key ],
        ) }`,
      );
      rows.push( {
        category: this.realAssetCategories[ key ],
        percent: this.realAssetsBreakdown.percent[ key ],
        dollar: this.realAssetsBreakdown.dollar[ key ],
        percentOfTotal: this.realAssetsBreakdown.portfolioPercent[ key ],
        color: this.realAssetsBreakdownChartColors[ i ],
      } );
      i++;
    }

    this.realAssetsBreakdown.rows = rows;

    this.realAssetsBreakdown.chart = {
      chartData: [
        {
          data: piePieces,
          backgroundColor: this.realAssetsBreakdownChartColors,
          hoverBackgroundColor: this.realAssetsBreakdownChartColors,
          hoverOffset: 20,
        },
      ],
      chartLabels: labels,
    };
  }

  /*
   * change the infoType to display a different set of columns
   * */
  toggleInfoType( type: string ) {
    if ( !this.loadingAccounts ) {
      // const name = this.account ? this.account.name : 'global';
      // console.log( `toggling infoType in allocation widget ${ name }...` );
      const newColumns = [
        ...this.getInitialColumns(),
        ...this.getColumns( type ),
      ];
      _.remove( newColumns, ( c: any ) => {
        if ( !this.showRE && c.prop === 'real_assets' ) {
          return true;
        }
        if ( this._state.globalVars.editing && c.prop === 'one_day_change' ) {
          return true;
        }
      } );

      this.addHeaderTemplate( newColumns );
      this.columns = newColumns;
      this.triggerRerenderOfDatatable(
        type !== this._state.defaultColumnSetLabel,
      );
      // console.log( `done toggling infoType in allocation widget ${ name }` );
    }
  }

  /*
   * get columns for a given infoType (column group)
   * */
  getColumns( type: string ) {
    const self = this;
    const columnSet: any = _.find(
      this._state.getAllColumnSets(),
      ( set: any ) => {
        return set.label === type;
      },
    );
    this.currentColumnSet = columnSet;
    const cols = _.clone( columnSet.columns );
    for ( let i = 0; i < cols.length; i++ ) {
      if (
        self.nonWidgetColumns &&
        self.nonWidgetColumns.indexOf( cols[ i ] ) >= 0
      ) {
        cols.splice( i, 1 );
        i--;
      }
    }
    return this._state.makeColumnSet( cols, 'alloc' ) || {};
  }

  /*
   * columns that should either always be in the widget (they are in the initial columns variable) or they
   * should not be shown in the widget at all
   * */
  nonWidgetColumns: any[] = [
    'cost_basis',
    'gain_loss',
    // 'cash',
    // 'bonds',
    // 'stocks',
    // 'Cash, Bonds, Stocks',
    'maturity_date',
    'expense_ratio',
    // 'real_assets',
  ];

  /*
   * this function determines whether this widget is the global one or account level and then gets the allocation data
   * from the AllocCalculator
   * */
  calculateAllocations( revised: boolean ) {
    // no need to do anything if there are no accounts/positions
    if ( this._accountManager.getAllOriginalPositionsIncludingManualAccounts().length > 0 ) {
      const allocDataType = revised ? 'Revised' : 'Current';
      if ( this.account ) {
        // if this widget is for a single account, use just the account and its positions for the aggregation
        let positions: Position[];
        let accounts: Account[];
        // give the widget the account only class
        this.widgetRowClass = 'account-widget-row';
        if ( revised ) {
          // if this is the revised version, just use the account in scope to aggregate
          accounts = [ this.account ];
          positions = this.account.positions;
        } else {
          // if this is not revised, get the original version of the account and it's positions
          const curAcct = _.find(
            this._accountManager.getAllOriginalAccounts(),
            ( a: any ) => {
              return a.account_id === this.account.account_id;
            },
          );
          if ( curAcct ) {
            accounts = [ curAcct ];
            positions = curAcct.positions;
          } else {
            accounts = [];
            positions = [];
          }
        }
        this.computeDisplayNumbers( AllocCalculator.calculateAllocations( positions || [], revised, {
          ra: this.showRE,
          accounts: accounts || [],
          revisableAccounts: this._accountManager.getAllRevisableAccounts(),
          todayIsTradingDay: this._state.globalVars.todayIsTradingDay,
        } ), allocDataType );
      } else {
        // tell benchmarkState to re aggregate to we get the latest numbers included
        this.benchmarkState.aggregatePositions();
        this.computeDisplayNumbers( revised ?
            ( this.showRE ? this.benchmarkState.aggregatedRevisedHolisticCached : this.benchmarkState.aggregatedRevisedInvestmentsCached )
            :
            ( this.showRE ? this.benchmarkState.aggregatedHolisticCached : this.benchmarkState.aggregatedInvestmentsCached ),
          allocDataType );
      }

      this.sortRows();
      this.notifyHeightChanged();
    }
  }

  /*
   * set the benchmark data in the allocation table
   * */
  setBenchmark() {
    if ( !this.account && this.relevantAllocData?.investment_total ) {
      this.setBMHelper();
    }
  }

  calcBMAdvisorFee( bmData: AllocationData, row: any, dollar: boolean ): BenchmarkDetailCell {
    let fee: BenchmarkDetailCell;
    if ( dollar ) {
      fee = {
        bm: bmData.management_fee.value,
        diff: row.expenses.dollar.advisorFees - bmData.management_fee.value,
      };
    } else {
      const deviation =
        row.expenses.percent.advisorFees - bmData.management_fee.avg;
      fee = {
        bm: bmData.management_fee.avg,
        diff: deviation,
        color: BenchmarkUtil.getDeviationColor(
          'Yields and Expenses',
          Math.abs( deviation ),
          this.thresholds,
        ),
      };
    }

    return fee;
  }

  calcBMExpenseRatio( bmData: AllocationData, row: any, dollar: boolean ) {
    let ratio;
    if ( dollar ) {
      const bmRatio = bmData.expense_ratio;
      ratio = {
        bm: bmRatio,
        diff: row.expenses.dollar.expense_ratio - bmRatio,
      };
    } else {
      const bmRatio = bmData.investment_total === 0 ? 0 : bmData.expense_ratio / bmData.investment_total;
      const deviation = row.expenses.percent.expense_ratio - bmRatio;
      ratio = {
        bm: bmRatio,
        diff: deviation,
        color: BenchmarkUtil.getDeviationColor(
          'Yields and Expenses',
          Math.abs( deviation ),
          this.thresholds,
        ),
      };
    }

    return ratio;
  }

  calcBMTotalExpenses( bmData: AllocationData, row: AllocationWidgetTableRow, dollar: boolean ): BenchmarkDetailCell {
    let total: {
      bm: number;
      diff: number;
      color?: any;
    };
    if ( dollar ) {
      const bmTotalDollar: number = bmData.management_fee.value + bmData.expense_ratio;
      total = {
        bm: bmTotalDollar,
        diff: row.expenses.dollar.totalExpenses as number - bmTotalDollar,
      };
    } else {
      const bmTotalPercent: number = bmData.investment_total === 0 ? 0 :
        ( bmData.expense_ratio + bmData.management_fee.value ) / bmData.investment_total;
      const deviation: number = row.expenses.percent.totalExpenses as number - bmTotalPercent;
      total = {
        bm: bmTotalPercent,
        diff: deviation,
        color: BenchmarkUtil.getDeviationColor(
          'Yields and Expenses',
          Math.abs( deviation ),
          this.thresholds,
        ),
      };
    }

    return total;
  }

  aggregatedBenchmarkData: AllocationData;

  setBMHelper() {
    this.thresholds = UsersUtil.checkPreferences(
      this._auth,
      'thresholds',
    ).thresholds;

    const benchmarkPositions: Position[] = BenchmarkUtil.convertBenchmarkToPositions(
      this.benchmarkState.benchmark, this.relevantAllocData.investment_total, this._state.globalVars.securities );

    for ( const bp of benchmarkPositions ) {
      OneDayChangeUtil.calcOneDayChange(
        bp,
        this._state.globalVars.todayIsTradingDay,
      );
    }

    this.benchmarkOneDayBreakdownRows = benchmarkPositions.filter( ( p: any ) => {
      return p.value !== 0;
    } );

    this.aggregatedBenchmarkData = AllocCalculator.calculateAllocations(
      benchmarkPositions,
      false,
      {
        ra: this.showRE,
        revisableAccounts: this._accountManager.getAllRevisableAccounts(),
        todayIsTradingDay: this._state.globalVars.todayIsTradingDay,
      },
    );

    // console.log( 'CALCULATED BENCHMARK DATA:' );
    // console.log( calculatedBenchmarkData );

    const allocDataType = 'Benchmark';

    const rowObj = this._state.globalVars.editing ? this.getRevisedRow() : this.getCurrentRow();

    const nw = this.showRE ? rowObj.net_worth.dollar : this.aggregatedBenchmarkData.net_worth;

    const expensesDollar: ExpensesInfoItem = {
      advisorFees: 0,
      expenseRatio: 0,
      totalExpenses: 0,
    };
    if ( nw !== 0 ) {
      expensesDollar.advisorFees = this.calcBMAdvisorFee( this.aggregatedBenchmarkData, rowObj, true );
      expensesDollar.expenseRatio = this.calcBMExpenseRatio( this.aggregatedBenchmarkData, rowObj, true );
      expensesDollar.totalExpenses = this.calcBMTotalExpenses( this.aggregatedBenchmarkData, rowObj, true );
    }

    const expensesPercent: ExpensesInfoItem = nw === 0 ? {
      advisorFees: 0,
      expenseRatio: 0,
      totalExpenses: 0,
    } : {
      advisorFees: this.calcBMAdvisorFee( this.aggregatedBenchmarkData, rowObj, false ),
      expenseRatio: this.calcBMExpenseRatio( this.aggregatedBenchmarkData, rowObj, false ),
      totalExpenses: this.calcBMTotalExpenses( this.aggregatedBenchmarkData, rowObj, false ),
    };

    if ( rowObj ) {

      const newTableDataRow: AllocationWidgetTableRow = BenchmarkUtil.getBenchmarkRowStartingValues(
        allocDataType,
        this.aggregatedBenchmarkData,
        this.showDiffs,
        nw as number,
        rowObj,
        {
          dollar: expensesDollar,
          percent: expensesPercent,
        },
        this.relevantAllocData,
        benchmarkPositions,
      );

      newTableDataRow.real_assets = {
        dollar: { bm: rowObj.real_assets.dollar as number, diff: 0 },
        percent: { bm: rowObj.real_assets.percent as number, diff: 0 },
        showDiffs: this.showDiffs,
        bm: true,
      };
      this.groupDeviations = {};

      BenchmarkUtil.setBenchmarkTableRowData(
        newTableDataRow,
        this.relevantAllocData,
        this.aggregatedBenchmarkData,
        this.showRE,
        this.showDiffs,
        rowObj,
        this.thresholds,
        this.groupDeviations,
      );

      this._state.globalVars.groupDeviations = this.groupDeviations;

      _.remove( this.tableData, r => {
        return r.allocDataType === allocDataType;
      } );
      this.tableData.push( newTableDataRow );

      this.rows = [ ...this.tableData ];
      this.sortRows();
      this.generateSlides();
      if ( this.deviceIsMobile ) {
        this.transposeTableData();
      }
      this._state.notifyDataChanged( 'benchmark.calculated', {} );
    }
  }

  getDeviationTooltip( color: string ) {
    return Util.getDeviationTooltip( color );
  }

  /*
   * Remove the Revised row from the allocation table (used when leabing edit mode)
   * */
  removeRevisedAllocations() {
    _.remove( this.tableData, r => {
      return r.allocDataType === 'Revised';
    } );
    this.rows = [ ...this.tableData ];
    this.sortRows();
    this.notifyHeightChanged();
  }

  /*
   * getter for the DatatableComponent for an instance of the widget
   * */
  getDataGrid() {
    return this.datatables.find( ( child: DatatableComponent, index, array ) => {
      return !!child;
    } );
  }

  /*
   * Minimize the widget (only used in account level widgets)
   * */
  minimize() {
    // const datatable = this._elRef.nativeElement.querySelector( '.ngx-datatable' );
    // datatable.style.display = this.minimized ? '' : 'none';
    this.minimized = !this.minimized;
    if ( !this.account ) {
      this.notifyHeightChanged();
    }
  }

  /*
   * Sorting function used to keep the current row on top and benchmark on bottom
   * sortValue for current row = 1
   * sortValue for revised row = 2
   * sortValue for benchmark row = 3
   * */
  sortRows() {
    const rows = [ ...this.rows ];

    rows.sort( ( a, b ) => {
      if ( a.sortValue > b.sortValue ) {
        return 1;
      } else if ( a.sortValue < b.sortValue ) {
        return -1;
      } else {
        return 0;
      }
    } );

    this.rows = [ ...rows ];
  }

  /*
   * Function to toggle whether to show the benchmark values or the difference the portfolio values from the benchmark
   * */
  toggleBenchmarkValues() {
    this.showDiffs = !this.showDiffs;
    const bmRow = this.getBenchmarkRow();
    for ( const key of Object.keys( bmRow ) ) {
      if ( typeof bmRow[ key ] === 'object' ) {
        bmRow[ key ].showDiffs = this.showDiffs;
      }
    }
    /*if ( !this.account ) {
     this.setBenchmark( this.benchmark );
     }*/
    this.generateSlides();
    this.triggerRerenderOfDatatable( true );
    if ( this.showDiffs ) {
      this.mobileTableHeaderHeight = 75;
    } else {
      this.mobileTableHeaderHeight = 35;
    }
    this.transposeTableData();
  }

  /*
   * Close all popovers
   * */
  closePopovers( event: any ) {
    this.popovers.forEach( ( popover: any ) => {
      popover.hide();
    } );
  }

  /*
   * Flip the view between table and charts
   * @param column {object} - column object where the flip was triggered from. used to go to the corresponding slide in the carousel
   * */
  flipTable( column?: any ) {
    // TODO: add showCharts and showGauges changes

    if ( !this.showTable ) {
      // going back to the table, so let's record the index the chart carousel was at so we might go back to it
      this.currentChartIndex =
        this.allocationCharts.chartCarousel.stepper.selectedIndex;
    }

    this.showTable = !this.showTable;
    this._spinnerService.hide( 0, this.chartsSpinnerSelector );
    this.flip = this.flip === 'inactive' ? 'active' : 'inactive';
    if ( !this.showTable ) {
      let index;
      if ( column ) {
        const group = this._state.getGroupFromColumn( column.prop );
        if ( group ) {
          index = _.findIndex( this.slides, ( s: any ) => {
            return s.group === group.label;
          } );
        }
      } else {
        index = this.currentChartIndex;
      }
      setTimeout( () => {
        this.allocationCharts.goToCarouselSlide( index );
      }, 500 );
    }
    this.notifyHeightChanged();
  }

  updatedIndex() {
    this.currentChartIndex =
      this.allocationCharts.chartCarousel.stepper.selectedIndex;
  }

  /*
   * Function to notify other components that the widget changed in height
   * */
  notifyHeightChanged() {
    if ( this.widgetRowDiv ) {
      if ( !this.account ) {
        setTimeout( () => {
          const widgetHeight = this.getHeight() - 11;
          this._state.notifyDataChanged(
            EVENT_NAMES.WIDGET_RESIZE,
            widgetHeight,
          );
          this._state.globalVars.globalDashboardHeight = widgetHeight;
        }, 500 );
      }
    }
  }

  getHeight() {
    if ( this.widgetRowDiv && !this.account ) {
      return this.widgetRowDiv.nativeElement.clientHeight + 4;
    }
  }

  getWidth() {
    if ( !this.account ) {
      if ( this.widgetRowDiv ) {
        return this.widgetRowDiv.nativeElement.clientWidth * 0.9;
      } else if ( this.loadingMessageDiv ) {
        return this.loadingMessageDiv.nativeElement.clientWidth * 0.9;
      } else {
        return window.innerWidth * 0.75;
      }
    }
  }

  onSelect( event: any ) {
    // console.log( event );
  }

  /*
   * Function to get allocation chart data object
   * @param group - { Object } - column group object retrieved from global state columnGroupings array
   * */
  getChartObject( group: any ) {
    const columns = [];
    if ( this.showRE && group.label === 'Cash, Bonds, Stocks' ) {
      columns.push( 'real_assets' );
    }
    const rowsToUse = [];
    if ( this.showDiffs ) {
      rowsToUse.push( 'Difference' );
      // rowsToUse.push( { type: 'string', role: 'annotation' } );
    } else {
      rowsToUse.push( this._state.globalVars.editing ? 'Revised' : 'Current' );
      // rowsToUse.push( { type: 'string', role: 'annotation' } );
      rowsToUse.push( 'Benchmark' );
      // rowsToUse.push( { type: 'string', role: 'annotation' } );
    }
    const datasets = this.getChartData( group, this.showDiffs, columns );

    const chartHeight = 125 * datasets[ 0 ].data.length;

    const labels = [];
    for ( const c of [ ...( group.columns || [] ), ...( columns || [] ) ] ) {
      if ( this._state.allColumnsObject[ c ]?.alloc ) {
        labels.push( this._state.allColumnsObject[ c ].alloc.name );
      }
    }

    const pipe = this.showPercentDiffs
      ? this.ripPercentPipe
      : this.ripCurrencyPipe;

    const formatXAxis = value => {
      if ( isNaN( value ) ) {
        return value;
      } else {
        return this.wrapNegativeInParens( value, pipe.transform( value ) );
      }
    };

    const formatYAxis = () => {
      const l = labels;
      return ( value, index, ticks ) => {
        return l[ index ];
      };
    };

    const chartObject: any = {
      chartHeight,
      datasets,
      labels,
      chartType: 'bar',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: this.chartIndexAxis,
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks (if this is the used axis)
              callback: formatYAxis(),
            },
          },
          x: {
            ticks: {
              // Include a dollar sign in the ticks (if this is the used axis)
              callback: formatXAxis,
            },
          },
        },

        plugins: {
          datalabels: {
            display: false,
          },
          title: {
            display: true,
            text: group.label,
            color: '#79afd7', // these three lines will need updating once we can move to chartjs 3.x
            font: {
              weight: 'bolder',
              size: this._state.chartTitleHeight,
            },
          },
          legend: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: ( context: TooltipItem<'bar'> ) => {
                return `$${ context.formattedValue }`;
              },
            },
          },
          // this is needed for datalabel annotations
          /*datalabels: {
           formatter: ( val: number ) => {
           return this.wrapNegativeInParens( val, pipe.transform( val ) );
           },
           color: ( context ) => {
           const index = context.dataIndex;
           const value = context.dataset.data[index];
           return value < 0 ? 'red' :  // draw negative values in red
           '#000000';
           },
           backgroundColor: '#FFFFFF',
           borderRadius: 10,
           borderColor: '#79afd7',
           borderWidth: 1,
           },*/
        },
      },
    };

    /*if ( !this.showDiffs ) {
     // anything that should change if we are plotting diffs instead of portfolio and benchmark value
     // chartObject.formatters.push( { formatter: this.chartNumberFormatter, colIndex: 1 } );
     // chartObject.roles.push( { type: 'string', role: 'annotation', index: 2 } );
     // chartObject.chartOptions.colors = [ '#85afd3', '#207bbc' ];
     }*/
    // add if statements that alter chartObject before being returned if needed
    /*    if ( [ 'Credit Quality Distribution', 'Maturity Range Distribution', 'Maturity Range Distribution', 'Stock Sector Distribution', 'Bond Sector Distribution' ].includes( group.label ) ) {

     }*/

    return chartObject;
  }

  /**
   *
   * @param val - value to check if negative or not
   * @param formatted - already formatted string of val
   */
  wrapNegativeInParens( val: number, formatted: string ): string {
    if ( val < 0 ) {
      return `(${ formatted })`;
    } else {
      return formatted;
    }
  }

  /*
   * @param dataSet - {String} - matches allocDataType property of the row for tableData
   * @param group - {Column Group Object} - column grouping to use to get data for a chart
   * @param columns - {Array<String} - individual column list to include in the data
   * @result - array - an array of objects that can be used in an charts component to display allocation data
   * */
  getChartData( group: any, diff: boolean, columns?: any ) {
    const rowsMap = {
      Current: this.getCurrentRow(),
      Revised: this.getRevisedRow(),
      Benchmark: this.getBenchmarkRow(),
    };

    const isPositive = context => {
      const index = context.dataIndex;
      const value = context.dataset.data[ index ];

      return value > 1000;
    };

    // initialize datasets
    const portfolioColor: string = '#f26722';
    const portfolioDataSet = {
      data: [],
      label: this._state.globalVars.editing ? 'Revised' : 'Current',
      color: portfolioColor,
      borderColor: portfolioColor,
      backgroundColor: portfolioColor,
      hoverBackgroundColor: portfolioColor,
      barPercentage: 1,
      // this is needed for datalabel annotations
      /*datalabels: {
       align: 'end',
       anchor: 'end',
       clamp: true,
       offset: ( context ) => {
       return isPositive( context ) ? -60 : 5;
       },
       },*/
    };
    const benchmarkColor: string = diff ? '#FFC60B' : '#00AEEF';
    const benchmarkDataSet = {
      data: [],
      label: 'Benchmark',
      color: benchmarkColor,
      borderColor: benchmarkColor,
      backgroundColor: benchmarkColor,
      hoverBackgroundColor: benchmarkColor,
      barPercentage: 1,
      // this is needed for datalabel annotations
      /*datalabels: {
       align: 'end',
       anchor: 'end',
       clamp: true,
       offset: ( context ) => {
       return isPositive( context ) ? -60 : 5;
       },
       },*/
    };

    if ( group ) {
      for ( const c of [ ...( group.columns || [] ), ...( columns || [] ) ] ) {
        if ( this._state.allColumnsObject[ c ]?.alloc ) {
          // const row = [ this._state.allColumnsObject[c].alloc.name ];
          // this block gets all the current, revised, benchmark values for each column based on what is passed in with rowsToUse
          let curValue = 0;
          let revValue = 0;
          let benchValue: any = 0;
          if ( rowsMap.Current && rowsMap.Current[ c ] ) {
            const dollar = rowsMap[ 'Current' ][ c ].dollar;
            curValue = dollar.totalExpenses ? dollar.totalExpenses : dollar;
          }
          if ( rowsMap.Revised && rowsMap.Revised[ c ] ) {
            const dollar = rowsMap[ 'Revised' ][ c ].dollar;
            revValue = dollar.totalExpenses ? dollar.totalExpenses : dollar;
          }
          if ( rowsMap.Benchmark && rowsMap.Benchmark[ c ] ) {
            let benchmarkCell: any = rowsMap[ 'Benchmark' ][ c ];
            if ( this.showPercentDiffs ) {
              benchmarkCell = benchmarkCell.percent;
            } else {
              benchmarkCell = benchmarkCell.dollar;
            }
            if ( benchmarkCell.totalExpenses ) {
              benchmarkCell = benchmarkCell.totalExpenses;
            }
            if ( diff ) {
              benchValue = benchmarkCell.diff;
            } else {
              benchValue = benchmarkCell.bm;
            }
          }

          // current or revised value
          const portfolioValue = this._state.globalVars.editing
            ? revValue
            : curValue;

          // portfolio value (current or revised based on the above statement)
          if ( !diff ) {
            portfolioDataSet.data.push( portfolioValue );
          } else {
            benchmarkDataSet.label = 'Difference';
          }

          // benchmark or diff from benchmark value (now that the diff toggle is in the widget menu, benchValue is the diff when the toggle is
          // clicked)
          benchmarkDataSet.data.push( benchValue );

          // benchmark annotation
          if ( diff && this.showPercentDiffs ) {
            // row.push( new RipsawPercentPipe().transform( benchValue ) );
          } else {
            // row.push( new RipsawCurrencyPipe().transform( benchValue ) );
          }
        }
      }
    }

    if ( diff ) {
      return [ benchmarkDataSet ];
    } else {
      return [ portfolioDataSet, benchmarkDataSet ];
    }
  }

  columnsThatDoNotNeedChartButtons = [ 'net_worth', 'expenses' ];

  /*
   * Function to let the view know if it should render the chart button for each column
   * */
  shouldColHaveChartButton( prop: string ) {
    return (
      !this.columnsThatDoNotNeedChartButtons.includes( prop ) && !this.account
    );
  }

  getColumnClass( prop: string ) {
    return this.shouldColHaveChartButton( prop )
      ? 'column-name-with-button'
      : 'column-name';
  }

  /*
   * Function to open the disclaimer modal for displaying the disclaimers
   * */
  openDisclaimerModal( index: number ) {
    this.disclaimersUtil.openDisclaimersDialog( index );
  }

  openGlossaryDialog( index: number ) {
    this.glossaryUtil.openGlossaryDialog( index );
  }

  openMobileDisclaimers( index: number ) {
    this._state.notifyDataChanged( EVENT_NAMES.OPEN_MOBILE_DISCLAIMERS, {
      disclaimerIndex: index,
    } );
  }

  /* This returns an object with dollar and percent values*/
  getColumnValue( colProp: string, allocDataType: string ) {
    const rowObj: any = this.getTableRow( allocDataType );
    if ( rowObj ) {
      return rowObj[ colProp ];
    } else {
      return '';
    }
  }

  getCurrentRow() {
    return this.getTableRow( 'Current' );
  }

  getRevisedRow() {
    return this.getTableRow( 'Revised' );
  }

  getBenchmarkRow() {
    return this.getTableRow( 'Benchmark' );
  }

  getTableRow( allocDataType: string ): AllocationWidgetTableRow {
    return _.find( this.tableData, ( row: any ) => {
      return row.header === allocDataType;
    } );
  }

  getNetWorth() {
    return this.getColumnValue( 'net_worth', 'Current' );
  }

  getOneDayReturn() {
    return this.getColumnValue( 'one_day_change', 'Current' );
  }

  getBenchmarkOneDayReturn() {
    return this.getColumnValue( 'one_day_change', 'Benchmark' );
  }

  transposeTableData() {
    let group = this._state.globalVars.currentColumnGroup;
    if ( !group ) {
      group = this._state.columnGroupings[ 0 ];
    }
    this.mobileColumns = this.getMobileColumns();
    this.transposedTableData = [];
    const keys = group.columns;

    for ( const key of keys ) {
      const colObj = this._state.allColumnsObject[ key ];
      if ( colObj && colObj.alloc ) {
        const obj = {
          dimension: colObj.alloc,
        };
        for ( const row of this.tableData ) {
          const rowType = row.allocDataType.toLowerCase();
          if ( !obj[ rowType ] ) {
            obj[ rowType ] = {};
          }
          const rowValue = row[ key ];
          obj[ rowType ] = rowValue;
        }
        this.transposedTableData.push( obj );
      }
    }
    this.shouldAddRealAssetsToTransposedTable( group );
    // console.log( this.transposedTableData );
    this.mobileRows = [ ...this.transposedTableData ];
  }

  openMobileCharts() {
    this.showTable = false;
    this._spinnerService.hide( 0, this.chartsSpinnerSelector );
    this._state.notifyDataChanged( EVENT_NAMES.OPEN_MOBILE_CHARTS, {
      slides: this.slides,
    } );
  }

  shouldAddRealAssetsToTransposedTable( group: any ) {
    if ( group.label === 'Cash, Bonds, Stocks' && this.showRE ) {
      const obj = {
        dimension: this._state.allColumnsObject[ 'real_assets' ].alloc,
      };
      for ( const row of this.tableData ) {
        const rowType = row.allocDataType.toLowerCase();
        if ( !obj[ rowType ] ) {
          obj[ rowType ] = {};
        }
        obj[ rowType ] = row[ 'real_assets' ];
      }
      this.transposedTableData.push( obj );
    }
  }

  openGlobalOverridesDialog() {
    this._overridesDialogUtil.openGlobalOverridesManager();
  }

  setOneDayBreakdownData() {
    this._state.globalVars.oneDayBreakdownData = {
      portfolioOneDayBreakdownRows: this.portfolioOneDayBreakdownRows,
      portfolioOneDayBreakdownColumns: this.portfolioOneDayBreakdownColumns,
      benchmarkOneDayBreakdownRows: this.benchmarkOneDayBreakdownRows,
      benchmarkOneDayBreakdownColumns: this.benchmarkOneDayBreakdownColumns,
      tableHeaderHeight: this.tableHeaderHeight,
      tableRowHeight: this.tableRowHeight,
      oneDayBreakdownTableMessages: this.oneDayBreakdownTableMessages,
      oneDayBreakdownTableCssClasses: this.oneDayBreakdownTableCssClasses,
    };

    this._state.notifyDataChanged( 'one.day.breakdown.set' );
  }

  openOneDayBreakdownDialog() {
    this.dialog.open( OneDayBreakdownDialogComponent, {
      minWidth: '85vw',
    } );
  }

  openOneDayMobileBreakdown() {
    this.showMobileOneDayPerf = true;
  }

  private showProgressAfterWorkspaceLoad(): void {
    this.appStoreService.loadedWorkspace$
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( () => {
        this.loadingAccounts = true;
        // console.log( 'log' );
      } );
  }

  protected readonly Array = Array;
}
