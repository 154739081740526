import { commonEnvironment } from './environment.common';

export const environment = {
  production: true,
  env: 'uat',
  version: '4.0.2.2279',
  debugging: false,
  appName: 'WealthFluent UAT',
  ripsawAPIBaseUrl: 'https://uat-api.ripsaw.co',
  common: commonEnvironment,
  stripePublishableKey: 'pk_test_qMchAbAaBV6SLIvB9B5gSA2A',
  yodleeFastLinkUrl: 'https://fl4.prod.yodlee.com/authenticate/production-59/fastlink/?channelAppName=tieredprod',
  finGoalFastLinkUrl: 'https://finapp.fingoalchannel.yodlee.com/authenticate/ripsaw-production/fastlink?channelAppName=fingoalchannel',
  heap_io_app_id: '552367347',
  wealthFluentOrigin: 'https://uat.wealthfluent.com',
};
