import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Account, AllocationData, AllocCalculator, Benchmark, BenchmarkUtil, Position } from '@ripsawllc/ripsaw-analyzer';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawPercentPipe } from '../theme/pipes';
import { AllocationWidgetComponent } from '../reusableWidgets/allocationWidget';
import * as _ from 'lodash-es';
import { GlobalState } from '../global.state';
import { AccountManager } from './accountManager';
import { MatDialog } from '@angular/material/dialog';
import { EVENT_NAMES } from './enums';
import { environment } from '../../environments/environment';
import { Logger } from './logger.service';


@Injectable()
export class BenchmarkState implements OnDestroy {

  private readonly onDestroy = new Subject<void>();

  benchmark: Benchmark = BenchmarkUtil.defaultBenchmark();

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  form: UntypedFormGroup = new UntypedFormGroup( {} );

  allocWidget: AllocationWidgetComponent;
  calculatedBenchmarkData: any;
  loading: boolean = false;
  cashBondStockWeightMap: any = {};
  selectedDeviation: number;
  benchmarkSecuritiesRetrieved: boolean = false;
  bmPortfolio: any;
  subscriberName: string = 'benchmarkState';

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               public dialog: MatDialog,
  ) {

    /*    _state.subscribe( EVENT_NAMES.PRICES_UPDATED, () => {
     this.loadBenchmark( this.initialUserBenchmark );
     }, this.subscriberName );*/

    _state.subscribe( [
      EVENT_NAMES.PRICES_UPDATED,
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
    ].join( ' | ' ), () => {
      if ( !!this.benchmark.id ) {
        // don't want to do this before the user's benchmark has been returned, because it will use the default and
        // mess everything up
        this.loadBenchmark( this.benchmark );
      }

    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.LOGOUT, () => {
      this.resetBenchmarkData( true );
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.LOGGED_IN, () => {
      Logger.log( 'logged in' );
      // this should only happen on subsequent logins from the same instance of the loaded app. Should not hit here when
      // the class instance is loaded the first time, because the subscription happens after the event is fired on first
      // login of a new instance
    }, this.subscriberName );

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_benchmarkState' ] = this;
    }
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [
      EVENT_NAMES.PRICES_UPDATED,
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      EVENT_NAMES.LOGOUT,
      EVENT_NAMES.LOGGED_IN,
    ].join( ' | ' ), this.subscriberName );
    this.onDestroy.next();
  }


  setUserBenchmark( benchmark: Benchmark ) {
    this.loadBenchmark( benchmark );
    this._state.notifyDataChanged( EVENT_NAMES.USER_BENCHMARK_LOADED );
  }


  /*
   * Function to set the benchmark her and globally and then emit it to the allocation widget, so it will aggregate and
   * set the benchmark there
   * @param benchmark {Object} - benchmark to be loaded to the form and the allocation widget
   * */
  loadBenchmark( benchmark: Benchmark, fromCompositionEditor?: boolean ) {
    if ( benchmark ) {
      this.loadHelper( benchmark, fromCompositionEditor );
    } else {
      // should probably tell the user something here
    }
  }

  loadHelper( benchmark: Benchmark, fromCompositionEditor?: boolean ) {
    this.investmentTotalCached = undefined; // reset so the next call caches it
    this.getInvestmentTotal();
    // in this case, we want all the data, because it is either a new user and there is no user entered data, or it
    // is the initial load from getUserBenchmark
    this.benchmark = _.cloneDeep( benchmark );
    this.benchmark.retired = BenchmarkUtil.isInvestorRetired( this.benchmark );
    this._state.globalVars.benchmark = this.benchmark;
    this.allocWidget?.setBenchmark();
    this.getInvestmentTotal();
    this._state.notifyDataChanged( EVENT_NAMES.USER_BENCHMARK_LOADED );
  }

  investmentTotalCached: number;
  aggregatedInvestmentsCached: AllocationData;
  aggregatedHolisticCached: AllocationData;
  aggregatedRevisedInvestmentsCached: AllocationData;
  aggregatedRevisedHolisticCached: AllocationData;

  /*
   * Function for getting the investment total from the allocation widget
   * */
  getInvestmentTotal() {
    // for now, let's just do this all the time, until the allocation widget code is refactored into a state class that
    // can be injected here to pull the data more easily
    this.investmentTotalCached = this.getInvestmentValueFromAllocCalculator();
    return this.investmentTotalCached;
    // }
  }

  /*
   * Function for getting investmentTotal directly from allocCalculator if needed
   * */
  getInvestmentValueFromAllocCalculator() {
    this.aggregatePositions();
    return this.aggregatedInvestmentsCached?.investment_total;
  }

  aggregatePositions() {
    const originalAccounts: Account[] = this._accountManager.getAllOriginalAccounts() ?? [];
    const originalPositions: Position[] = this._accountManager.getAllOriginalPositionsIncludingManualAccounts() || [];

    this.aggregatedInvestmentsCached = AllocCalculator.calculateAllocations( originalPositions, false, {
      ra: false,
      accounts: originalAccounts,
      todayIsTradingDay: this._state.globalVars.todayIsTradingDay,
    } );
    this.aggregatedHolisticCached = AllocCalculator.calculateAllocations( originalPositions, false, {
      ra: true,
      accounts: originalAccounts,
      todayIsTradingDay: this._state.globalVars.todayIsTradingDay,
    } );
    const revisableAccounts: Account[] = this._accountManager.getAllRevisableAccounts() ?? [];
    const revisablePositions: Position[] = this._accountManager.getAllPositionsIncludingManualAccounts() ?? [];
    this.aggregatedRevisedInvestmentsCached = AllocCalculator.calculateAllocations( revisablePositions, true, {
      ra: false,
      accounts: revisableAccounts,
      todayIsTradingDay: this._state.globalVars.todayIsTradingDay,
    } );
    this.aggregatedRevisedHolisticCached = AllocCalculator.calculateAllocations( revisablePositions, true, {
      ra: true,
      accounts: revisableAccounts,
      todayIsTradingDay: this._state.globalVars.todayIsTradingDay,
    } );

  }


  /**
   * when a user logs out, most of the class variables need to be reset to their initial values so signing in as a
   * different user doesn't keep stale data from the previous
   * @param {Boolean} onLogout - whether this is called on logout and extra things should be reset
   */
  resetBenchmarkData( onLogout?: boolean ) {

    if ( onLogout ) {
      this.allocWidget = undefined;
    }

    this.benchmark = BenchmarkUtil.defaultBenchmark();

    this.calculatedBenchmarkData = undefined;

    this.loading = false;
    this.cashBondStockWeightMap = {};
    this.selectedDeviation = undefined;
    this.benchmarkSecuritiesRetrieved = false;
    this.bmPortfolio = undefined;

  }

}
